:root {
  background-color: #353168;
  background-image: url(./bg.png);
  background-repeat: repeat-x;
  text-align: center;
}

.App {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-width: 600px;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
}

button:hover {
  cursor: pointer;
}

header {
  margin: 1vh 0;
}
