.VariantChooser-container {
  background-color: #5ba058;
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.VariantChooser-button {
  width: 78px;
  height: 76px;
  background-image: url(./btn_item_off.png);
  background-color: #5ba058;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.VariantChooser-button:hover,
.selected {
  background-image: url(./btn_item_on.png);
}

.VariantChooser-button img {
  object-fit: cover;
  max-width: 52px;
  max-height: 52px;
}

.VariantChooser-button:hover > .VariantChooser-button-img {
  background-color: #8cbf6e;
}
