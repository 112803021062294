.HeroScreen-container {
  width: 410px;
  height: 670px;
  background-image: url(./monitor_left.png);
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 10px;
}

canvas {
  margin-top: 35px;
}

button.download {
  margin-top: 30px;
  width: 100px;
  height: 66px;
  background-image: url(./btn_download_off.png);
  background-color: #b1a0b4;
  animation: 2s linear 0s infinite running button-press;
}

button.download:hover {
  background-image: url(./btn_download_on.png);
  animation: none;
}

.download-pad {
  margin-top: 120px;
  height: 170px;
  background-image: url(./download_console.png);
  background-position: top;
  background-repeat: no-repeat;
}

@keyframes button-press {
  75% {
    background-image: url(./btn_download_off.png);
  }

  76% {
    background-image: url(./btn_download_on.png);
  }

  100% {
    background-image: url(./btn_download_on.png);
  }
}
